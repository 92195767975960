import { Controller } from '@hotwired/stimulus';

/**
 * Toggle CSS classes of some elements.
 *
 * @example - toggle the visibility of an element
 * <div data-controller="reveal" data-reveal-hidden-class="hidden">
 *   <button
 *     type="button"
 *     data-reveal-target="toggler"
 *     data-action="click->reveal#toggle"
 *   >
 *     Toggle
 *   </button>
 *
 *   <div class="hidden" data-reveal-target="item">
 *     <p>Some content</p>
 *     <button type="button" data-action="click->reveal#hide">
 *       Hide
 *     </button>
 *   </div>
 * </div>
 */
export default class extends Controller {
  static targets = ['item', 'toggler'];

  static classes = ['hidden'];

  get isShown() {
    return !this.itemTarget.classList.contains(this.hiddenClass);
  }

  connect() {
    if (!this.hasHiddenClass) {
      throw new Error('data-reveal-hidden-class must not be empty');
    }
  }

  // Actions

  toggle() {
    if (this.isShown) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.itemTargets.forEach((element) => {
      element.classList.remove(...this.hiddenClasses);
    });

    this.itemTarget.focus();

    this.togglerTargets.forEach((element) => {
      element.setAttribute('aria-expanded', true);
    });
  }

  hide() {
    this.itemTargets.forEach((element) => {
      element.classList.add(...this.hiddenClasses);
    });

    this.togglerTargets.forEach((element) => {
      element.setAttribute('aria-expanded', 'false');
    });
  }
}
