import { Controller } from '@hotwired/stimulus';

/**
 * Enable or disable some elements if at least one checkbox is checked.
 *
 * @example - control the disabled state of a button
 * <div data-controller="checkbox-enabler">
 *   <label>
 *     <input
 *       type="checkbox"
 *       data-checkbox-enabler-target="enabler"
 *       data-action="change->checkbox-enabler#update">
 *     Option 1
 *   </label>
 *   <label>
 *     <input
 *       type="checkbox"
 *       data-checkbox-enabler-target="enabler"
 *       data-action="change->checkbox-enabler#update">
 *     Option 2
 *   </label>
 *   <button type="submit" data-checkbox-enabler-target="item">
 *     Submit
 *   </button>
 * </div>
 */
export default class extends Controller {
  static targets = ['enabler', 'item'];

  connect() {
    this.update();
  }

  // Actions

  update() {
    if (this.enablerTargets.find((element) => element.checked)) {
      this.enable();
    } else {
      this.disable();
    }
  }

  enable() {
    this.itemTargets.forEach((element) => {
      element.disabled = false;
    });
  }

  disable() {
    this.itemTargets.forEach((element) => {
      element.disabled = true;
    });
  }
}
