// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = window.STATIC_URL || '/';

//
// Fonts
//

// https://fontsource.org/fonts/work-sans
import '@fontsource/work-sans/400.css';
import '@fontsource/work-sans/400-italic.css';
import '@fontsource/work-sans/700.css';
import '@fontsource/work-sans/700-italic.css';

// https://fontsource.org/fonts/montserrat
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/700.css';

//
// Application
//

import { Application } from '@hotwired/stimulus';
import CheckboxEnablerController from './controllers/checkbox_enabler_controller';
import DismissibleController from './controllers/dismissible_controller';
import DropdownController from './controllers/dropdown_controller';
import RevealController from './controllers/reveal_controller';

window.Stimulus = Application.start();
window.Stimulus.register('checkbox-enabler', CheckboxEnablerController);
window.Stimulus.register('dismissible', DismissibleController);
window.Stimulus.register('dropdown', DropdownController);
window.Stimulus.register('reveal', RevealController);
