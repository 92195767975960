import { Controller } from '@hotwired/stimulus';

/**
 * Toggle contextual overlays for displaying lists of links.
 *
 * @example - toggle a submenu
 * <div data-controller="dropdown" data-dropdown-active-class="active">
 *   <button
 *     type="button"
 *     aria-label="Toggle the menu"
 *     aria-controls="submenu"
 *     aria-expanded="false"
 *     data-action="click->dropdown#toggle:stop keydown.esc->dropdown#close click@window->dropdown#close"
 *     data-dropdown-target="toggler"
 *   >
 *     Toggle
 *   </button>
 *   <div id="submenu" style="display: none;" data-dropdown-target="menu">
 *     <a href="#">Account settings</a>
 *     <a href="#">Support</a>
 *     <a href="#">License</a>
 *   </div>
 * </div>
 */
export default class extends Controller {
  static targets = ['menu', 'toggler'];

  static classes = ['active'];

  get isOpen() {
    return this.togglerTarget.getAttribute('aria-expanded') !== 'false';
  }

  connect() {
    if (this.togglerTargets.length !== 1) {
      throw new Error('One "toggler" target must be defined');
    }

    if (this.menuTargets.length !== 1) {
      throw new Error('One "menu" target must be defined');
    }
  }

  // Actions

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.menuTarget.style.display = '';
    this.menuTarget.focus();

    this.togglerTarget.classList.add(...this.activeClasses);
    this.togglerTarget.setAttribute('aria-expanded', true);
  }

  close(event) {
    // Prevent unexpected behaviour if this action is called by click@window
    if (!this.isOpen || (event && this.menuTarget.contains(event.target))) {
      return;
    }

    this.menuTarget.style.display = 'none';

    this.togglerTarget.classList.remove(...this.activeClasses);
    this.togglerTarget.setAttribute('aria-expanded', 'false');
  }
}
